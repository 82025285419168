'use client'

import { useRouter } from "next/navigation"

import IconChevronLeft from "@/assets/icon/chevron-left.svg";

// TODO:Timo issue: when page is called directly (through browser address bar), back button will go back to any other page. thats not good. it means you will never be able to go back to the club page for example

export default function SimpleLayoutPageBackButton({className} : {className?:string}) {
  const router = useRouter()
 
  return (
    <button type="button" className={`button text-white-forced ${className || ""}`} onClick={() => router.back()}>
      <IconChevronLeft className="button__icon--left" />Zurück
    </button>
  )
}
