'use client'
import { useDebounce } from '@/hooks/useDebounce';
import { Club } from '@/sources/kicker-amateur/types';
import { apiClubSearchPath, pwaHomeUrl } from '@/utils/urlHelper';
import React, { useState, useCallback } from 'react';
import Link from 'next/link'
import { ClubImage } from '../pwa/components/TeamImage';
import Close from "@/assets/icon/close.svg";
import Search from "@/assets/icon/search.svg";
import Button from '../pwa/components/Button';

export function ClubSearchField({url}: {url:string}) {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState<Club[]>([]);
    const [loading, setLoading] = useState(false);

    const searchClubs = async () => {
        if (!searchTerm) return;
        try {
          setLoading(true);
            const response = await fetch(apiClubSearchPath(encodeURIComponent(searchTerm)));
            if (!response.ok) {
                throw new Error('Fehler bei der Suche.');
            }
            const data: Club[] = await response.json();
            setResults(data);
        } catch (error) {
            console.error(error);
            setResults([]); 
        }
        setLoading(false);
    };

    const debouncedSearch = useCallback(() => {
        searchClubs();
    }, [searchTerm]);

    useDebounce(debouncedSearch, 500, [searchTerm]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div className="w-full relative">
            <div className="duration-fast relative
              hover:scale-[1.01]
              focus-within:scale-100
              ">
              <input
                  type="text"
                  placeholder="Suche nach deinem Verein…"
                  value={searchTerm}
                  onChange={handleChange}
                  className="w-full rounded-lg text-lg text-gray-900-forced p-4 pl-12 pr-8 
                  outline-none focus:ring-2 ring-primary-variant-strong
                  duration-fast peer"
              />

              <div className="absolute top-0 left-0 bottom-0 pl-3 flex items-center text-gray-900-forced opacity-50 peer-focus:opacity-100">
                <Search className="absolute w-6 h-6" />
              </div>
              {searchTerm && !loading &&
                <Button color="link-muted" className="absolute right-0 top-0 bottom-0" onClick={() => setSearchTerm("")}>
                  <Close />
                </Button>
              }
              { loading &&
                <div className="absolute top-0 right-0 bottom-0 flex items-center pr-3">
                  <div className="loading-spinner text-gray-900-forced" />
                </div>
              }
            </div>
            { searchTerm && 
              <div className="absolute z-10 top-full left-0 right-0 bg-white-forced text-gray-900-forced max-h-72 rounded-b-lg shadow-lg -mt-1.5 flex flex-col">
                <div className="mx-4 border-t border-gray-150-forced"></div>
                {results.length > 0 
                  ? 
                    <ul className="flex-grow overflow-y-auto pt-2 pb-2 overflow-x-hidden">
                      {results.map(club => (
                          <li key={club.Id}>
                            <Link href={url.replace('REPLACE', club.Id!.toString())} className="px-4 py-2 flex text-lg interactive-cell gap-2">
                              <ClubImage size="6" club={club} loading="eager" />
                              {club.OfficalName}
                            </Link>
                          </li>
                      ))}
                    </ul>
                  :
                  <div className="py-4 text-center font-bold text-muted">
                    Kein Verein gefunden
                  </div>
                }
              </div>
            }
        </div>
    );
  }

